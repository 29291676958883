import React from 'react'
import logo1 from "../Assets/LOGO/Logo 1.png"

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navabr-style">
    <div className="container-fluid">
        
      <div className='navbar-brand ' style={{marginLeft : "50px", marginRight: "80px"}}>
        <a href="/">
        <img src={logo1} alt="" width={150} />
        </a>
      </div>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
     
      <div className="collapse navbar-collapse " id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2   w-100 justify-content-evenly mb-lg-0">
          <li className="nav-item">
          <a className="nav-link active fw-bold" aria-current="page" href="/#/who-we-are">Who We Are</a>
          </li>
          <li className="nav-item">
            <a className="nav-link active fw-bold" aria-current="page" href="/#/service-&-production">Service & Production</a>
          </li>
          <li className="nav-item">
            <a className="nav-link active fw-bold" aria-current="page" href="/#/article-&-caseStudy">Articles & Case Study</a>
          </li>
        </ul>
      <button className='btn col-md-2 col-sm-3   btn-dark ' style={{marginRight:"40px"}} >Reach Tekno </button>
      </div>

    </div>
  </nav>
  )
}

export default Header