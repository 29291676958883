import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Content from '../../Components/Content'

const LayoutUser = () => {
  return (
    <div className='bg-1  ' style={{width: "100%"}}>
          <Header/>
              <Content />
          <Footer/>
    </div>
  )
}

export default LayoutUser