import React from 'react'
import routes from '../Routes';
import { Route, Routes } from 'react-router-dom';

const Content = () => {
  return (
      <Routes>
        {
          routes.map((route,index) => (
            <Route
            key={index}
            exact={route.exact}
            path={route.path}
            element={< route.element />}
            />
          ))
        }
        
      </Routes>
  )
}

export default Content