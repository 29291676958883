import React from "react";
import logo2 from "../Assets/LOGO/Logo 2.png";
import {
  BsFacebook,
  BsLinkedin,
  BsWhatsapp,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { PiMapPinLine } from "react-icons/pi";
const Footer = () => {
  return (
    <div className="bg-footer-1 text-white padding-content-footer ">
      <div className="  row p-2 justify-content-between">
        <div className="col-md-4 card-logo-footer  d-flex flex-column">
          <img
            className="  logo-footer"
            style={{ zIndex: 1 }}
            src={logo2}
            width={250}
            alt=""
          />
          <span className="tagline-logo-footer" style={{ zIndex: 10 }}>
            “Simply innovation, that it is!”
          </span>
          <div className="fs-4 col-md-8 ms-2 d-flex   medsos-footer justify-content-evenly mt-4 ">
          <a href="https://www.linkedin.com/company/pt-tekno-digital-nusantara-teknoverse/" className="icon-link icon-link-hover text-white">  <BsLinkedin /></a>
          <a href="https://www.instagram.com/teknoverse.iot/" className="icon-link icon-link-hover text-white">   <BsInstagram /></a>
          <a href="https://www.youtube.com/@teknoverseiot1457" className="icon-link icon-link-hover text-white">   <BsYoutube /></a>
          <a href="" className="icon-link icon-link-hover text-white">  <BsFacebook /></a>
           
           
          
          </div>
        </div>
        <div className=" col-md-8  row m-0 p-0">
          <div className="col-md-3 item-footer">
            <span className="title-sub-footer">EXPLORE</span>
            <ul className=" sub-menu-footer">
              <li>Home</li>
              <li>Who We Are</li>
              <li>Serviec & Production</li>
              <li>Articles & Case Study</li>
              <li>Reach Us</li>
            </ul>
          </div>
          <div className="col-md-4 item-footer">
            <span className="title-sub-footer">CONTACT US</span>
            <ul className=" sub-menu-footer-contact">
              <li className="d-flex align-items-center">
                <BsWhatsapp className="text-color-1 icon" />{" "}
                <span className="content">+62-877-8959-6859</span>
              </li>
              <li className="d-flex align-items-center">
                <BsWhatsapp className="text-color-1 icon" />{" "}
                <span className="content">+62-877-8959-6869</span>
              </li>

              <li className="d-flex align-items-center">
                <AiOutlineMail className="text-color-1 icon" />{" "}
                <span className="content">marketing@teknoverse.id</span>
              </li>
            </ul>
          </div>
          <div className="col-md-4 item-footer">
            <span className="title-sub-footer">HEADQUARTER</span>
            <ul className=" sub-menu-footer-contact">
              <li className="d-flex align-items-center">
                <PiMapPinLine className="text-color-1 icon" />{" "}
                <span className="content">
                  PT Tekno Digital Nusantara (TeknoVerse)
                </span>
              </li>
              <li className="sub-menu">
                Ruko Suncity Square Block A No.43 Jl. Mayor M. Hasibuan,
                Margajaya 17141, South Bekasi West Java, Indonesia
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-4 " />
        <div className=" text-center" style={{fontSize:"14px"}}>
<span>© PT. Tekno Digital Nusantara, All Right Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
