import Home from "./Views/User Pages/Home"
import WhoWeAre from "./Views/User Pages/WhoWeAre"
import ServiceAndProduction from "./Views/User Pages/ServiceAndProduction"
import ArticlesAndCaseStudy from "./Views/User Pages/ArticlesAndCaseStudy"

const routes = [
    {
        path: "/",
        name : "Home",
        exact: true,
        element : Home
    },
    {
        path: "/service-&-production",
        name : "Service & Production",
        element : ServiceAndProduction
    },
    {
        path: "/article-&-caseStudy",
        name : "Product",
        element : ArticlesAndCaseStudy
    },
    {
        path: "/who-we-are",
        name : "Who We Are",
        element : WhoWeAre
    },
]
export default routes