import React from 'react'

const ArticlesAndCaseStudy = () => {
  return (
    <div className='section-1'>
      
    </div>
  )
}

export default ArticlesAndCaseStudy