import React from "react";
import homePict1 from "../../Assets/IMG/Home Pict 1.png";
import homePict2 from "../../Assets/IMG/Home Pict 2.png";
import homePict3 from "../../Assets/IMG/Home Pict 3.png";
import logoToyota from "../../Assets/LOGO/customer/Toyota.png";
import logoAstra from "../../Assets/LOGO/customer/Astra.png";
import logoCmw from "../../Assets/LOGO/customer/CMW.png";
import logoMai from "../../Assets/LOGO/customer/MAI.png";
import logoNsi from "../../Assets/LOGO/customer/NSI.png";
import logoNki from "../../Assets/LOGO/customer/NKI.png";
import imgDiscover1 from "../../Assets/IMG/Discover 2.png";
import imgDiscover2 from "../../Assets/IMG/Discover 1.jpg";

const Home = () => {
  return (
    <div className="">
      <div className="  d-flex  section-1 ">
        <img src={homePict1} className="mt-5 img-home-1" alt="" />
        <div className=" d-flex  align-items-center" style={{ zIndex: 5 }}>
          <div className="row col-md-5 g-3 content-section-1 item">
            <div className=" title-text ">we’re here to make your business</div>
            <span className="text-color-1 mt-4 primary-title-text">
              Evolve!
            </span>
            <div className="text-justify mt-4 content-text">
              We are your solution partner in IT that focus on granting your
              idea into reality with a massive upgrade. Providing the best
              solution is our #1st Priority!
            </div>
            <div className="">
              <button className="btn btn-dark mt-4 fw-bold">
                Explore more
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex section-1 bg-2 ">
        <img src={homePict2} className="img-section-2 mobile-d-none" alt="" />
        <div className="col-md-12  d-flex ">
          <div className=" d-flex  justify-content-center content-section-2 ms-auto col-md-6">
            <div className="col-md-10  g-0  text-white  justify-content-center d-flex flex-column ">
              <span className="title-text-2 ">
                Unleashing Digital <br /> Transformation: <br />
                Empowering Industries with <br /> Teknoverse's Innovation and{" "}
                <br /> Excellence
              </span>
              <p className=" mt-md-5 mt-3  text-justify content-text text-color-2">
                In the rapidly evolving digital landscape, businesses strive to
                unlock their full potential through digital transformation.
                Teknoverse, a dynamic startup, partners with industries to
                unleash the power of digital technologies, optimizing
                manufacturing processes, and driving unparalleled growth.
                Welcome to the world of Teknoverse, where innovation meets
                industrial excellence.
              </p>
              <div className="mt-md-3 mt-2">
                <button className="btn btn-outline-light fw-bold">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" d-flex py-3 flex-column px-3 align-items-center section-1">
        <div className="text-center mt-md-5  ">
          <span className="title-text ">Meet our most valued customers</span>
        </div>
        <div className=" mt-4 item-container   ">
          <div className="rolling-costumer">
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoToyota} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoNsi} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoMai} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoCmw} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoAstra} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoNki} className="img-rolling-costumer" alt="" />
            </div>
          </div>
        </div>
        <p className="title-text mt-0 mb-0">
          Discover what{" "}
          <span className="text-color-1 primary-title-text-2">YOU</span> want
        </p>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="content-text text-color-2 col-md-8 text-center">
            Discover what you want with TeknoVerse' s IT consulting and software
            solutions. Our experts will guide you in identifying unique business
            needs and provide tailored solutions to streamline operations and
            enhance productivity. Unlock your business's potential with
            TeknoVerse' s cutting-edge technology.
          </p>
          <div className="container mt-4 mb-5">
            <div className="row justify-content-evenly g-2">
              <div className="card-container col-md-3 ">
                <img src={imgDiscover2} className="img-card" alt="" />
                <div className="card-content  p-2">
                  <span className="title-text-3 text-center">
                    Professional <br /> Consultation
                  </span>
                </div>
              </div>
              <div className="card-container col-md-3 ">
                <img src={imgDiscover1} className="img-card" alt="" />
                <div className="card-content  p-2">
                  <span className="title-text-3 text-center">
                    Product <br /> Solutions
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-1 d-flex bg-2 px-3 align-items-center">
        <img src={homePict3} className="img-home-1" alt="" />
        <div className="row text-white">
          <p className="title-text mt-0 mb-0">
            Get More <br />{" "}
            <span className="text-color-1 primary-title-text-2">
              {" "}
              INSIGHTFUL{" "}
            </span>{" "}
            <br /> Ideas from our <br />
            news & Articles
          </p>
          <p className="content-text text-color-2   col-md-5 mt-5">
            Dive into our captivating news & articles for a glimpse into the
            future. Discover game-changing trends and cutting-edge technologies
            that are reshaping industries. Stay informed, inspired, and embrace
            the limitless possibilities with TeknoVerse' s thought-provoking
            content.
          </p>
          <div className="mt-md-3 mt-2">
            <button className="btn btn-outline-light fw-bold">Read More</button>
          </div>
        </div>
      </div>

      <div className="section-1_2 flex-column justify-content-center  d-flex px-3 align-items-center">
        <p className="title-text text-center p-0 m-0">
          Embrace the future & reach us{" "}
          <span className="text-color-1 primary-title-text-2"> now!</span>{" "}
        </p>

        <button className="btn btn-dark mt-4 fw-bold">Reach Tekno</button>
      </div>
    </div>
  );
};

export default Home;
