import React from "react";
import section1_1 from "../../Assets/IMG/who-we-are-section-1-0.1.png";
import section1_2 from "../../Assets/IMG/who-we-are-section-1-0.2.png";
import section1_3 from "../../Assets/IMG/who-we-are-section-1-0.3.png";
import section1_4 from "../../Assets/IMG/who-we-are-section-1-0.4.png";
import section4_1 from "../../Assets/IMG/who-we-are-section-4-0.1.png";
import section3_1 from "../../Assets/IMG/who-we-are-section-3-0.1.png";

const WhoWeAre = () => {
  return (
    <div className="pt-5 ">
      <div className="section-1  align-items-center ">
        <div className="col-md-12 ">
          <div className="row me-5">
            <div className=" col-md-6   ">
              <div className="row  g-4 p-0 ">
                <div className="col-6 justify-content-center d-flex ">
                  <img src={section1_1} className="img-size-3 " alt="" />
                </div>
                <div className="col-6 justify-content-center d-flex">
                  <img src={section1_2} className="img-size-3 " alt="" />
                </div>
                <div className="col-6 justify-content-center d-flex">
                  <img src={section1_3} className="img-size-3 " alt="" />
                </div>
                <div className="col-6 justify-content-center d-flex">
                  <img src={section1_4} className="img-size-3 " alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-center  text-end p-2 d-flex flex-column ">
              <span className="title-text">About</span>
              <span className="primary-title-text fw-bold text-color-1 font-italic">
                TeknoVerse
              </span>
              <p className="content-text-2 text-color-2">
                Welcome to the Era of digital <br /> Transformation with
                Teknoverse!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-1_2 bg-2 d-flex  justify-content-center px-5 py-5 flex-column text-white">
        <span className="title-text text-color-1 ">our Story</span>
        <div className="row mt-5">
          <div className="col-md-4">
            <p>
              In today's digital era, industrial sectors must embrace
              digitalization for enhanced efficiency and competitive edge.
              However, many companies face challenges in their digital
              transformation journey due to unprepared human resources,
              underutilized facilities, and limited IoT connectivity.
            </p>
          </div>
          <div className="col-md-4">
            <p>
              Teknoverse, a startup focused on industrial digital
              transformation, collaborates with industries to maximize digital
              potential. Our expertise and innovative solutions address these
              challenges, optimizing digital capabilities, integrating IoT
              devices, and improving overall operational efficiency.
            </p>
          </div>
          <div className="col-md-4">
            <p>
              With Teknoverse, experience comprehensive digital transformation
              and unlock your industrial potential. Simply Innovation, That it
              is!
            </p>
          </div>
        </div>
      </div>
      <div className="section-1 d-flex align-items-center ">
        <img src={section4_1} className="  img-section-primary-left" alt="" />
        <div className="pe-5 ms-auto col-md-5 text-end" style={{ zIndex: 10 }}>
          <span className="title-text text-color-1 ">Vision</span>
          <p className=" mt-5">
          Our Company is aiming to become the best and trusted information and operational technology integrator company in the Asia-Pacific Region.
          </p>
        
        </div>
      </div>
      <div className="section-1 bg-2 text-white d-flex align-items-center">
        <img src={section3_1} className=" img-section-primary-right" alt="" />
        <div
          className="ps-5 me-auto col-md-5 text-start"
          style={{ zIndex: 10 }}
        >
          <span className="title-text text-color-1 ">mission</span>
          <p className=" mt-5">
          In order to fulfill our company vision, we have missions to accomplish. Which are:
          <ol>
            <li>Speed up digital transformation process in manufacture industry</li>
            <li>Speed up digital transformation process in manufacture industry</li>
            <li>Speed up digital transformation process in manufacture industry</li>
          </ol>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
