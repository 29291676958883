import * as React from "react"
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom"
import Login from "./Pages/Auth/Login"
import Register from "./Pages/Auth/Register"
import LayoutUser from "./Layout/User Pages/LayoutUser"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "./Assets/MyCss.css"

function App() {
  return (

    <HashRouter basename="/">
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login/>} />
        <Route  exact path="/register" name="Register Page" element={<Register/>} />
        <Route  path="*" name="Default Page" element={<LayoutUser />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
