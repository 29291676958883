import React from "react";
import bgSection1 from "../../Assets/IMG/bg-section1-service&product.png";
import bgSection2 from "../../Assets/IMG/bg-section2-service&product.png";
import logoToyota from "../../Assets/LOGO/customer/Toyota.png";
import logoAstra from "../../Assets/LOGO/customer/Astra.png";
import logoCmw from "../../Assets/LOGO/customer/CMW.png";
import logoMai from "../../Assets/LOGO/customer/MAI.png";
import logoNsi from "../../Assets/LOGO/customer/NSI.png";
import logoNki from "../../Assets/LOGO/customer/NKI.png";
import bgSection3 from "../../Assets/IMG/service&product-0_0.png";
import projectAndon from "../../Assets/IMG/projectAndon.png";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const ServiceAndProduction = () => {
  return (
    <div>
      <div className="  section-bg-img d-flex p-0">
        <img src={bgSection1} className="bg-img-1" alt="" />
        <div className="col-md-12 d-flex pe-5 flex-column bg-light-left text-white justify-content-center  pe-md-5 text-end ms-auto front-index">
          <span className="title-text">Consultation Service</span>
          <p className=" content-text text-color-2 col-md-5 mt-5 ms-auto">
            Unlock the potential of your business with Teknoverse's expert IT
            consultation. Let us navigate the digital landscape and empower your
            growth with innovative solutions.
          </p>
        </div>
      </div>
      <div className="p-0  section-bg-img d-flex align-items-center justify-content-center">
        <img src={bgSection2} className="bg-img-1" alt="" />
        <div className="row overflow-auto  align-items-center justify-content-center   front-index line-card-1-section">
          <span className="title-text text-center col-12">
            Guiding You Towards Future Through Our Experts
          </span>
          <div
            className="col-12 mt-4 row justify-content-evenly align-items-center"
            style={{ overflow: "hidden" }}
          >
            <div className="col-md-3  d-flex flex-column align-items-center justify-content-center">
              <span className="sub-title-text">Strategic Consulting</span>
              <p className=" text-color-3 content-text text-justify  mt-3">
                Our strategic consulting services are tailored to your unique
                business needs. We provide expert insights and guidance to help
                you gain a competitive edge in the market.
              </p>
            </div>
            <div className="col-md-3  d-flex flex-column align-items-center justify-content-center">
              <span className="sub-title-text">System Integration</span>
              <p className=" text-color-3 content-text text-justify mt-3">
                We specialize in seamless system integration, optimizing your
                operations and enhancing efficiency. Our team ensures that all
                your systems work together harmoniously, streamlining your
                processes and maximizing productivity.
              </p>
            </div>
            <div className="col-md-3  d-flex flex-column align-items-center justify-content-center">
              <span className="sub-title-text">Future-Proof Solutions</span>
              <p className=" text-color-3 content-text  text-justify mt-3">
                We offer future-proof solutions that scale and adapt to your
                evolving needs. Our customized IT systems are designed to
                support your long-term success, allowing you to stay ahead in a
                rapidly changing technological landscape.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
       
        className="section-1   d-flex flex-column align-items-center justify-content-center "
      >
        <img
          src={bgSection3}
          width={"25%"}
          style={{ top: "20rem" }}
          className="img-section-primary-left-only"
          alt=""
        />
        <div className="text-center mt-md-5  ">
          <span className="title-text ">Meet our most valued customers</span>
        </div>

        <div className=" mt-4 item-container   " style={{ zIndex: 10 }}>
        <div className="rolling-costumer">
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoToyota} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoNsi} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoMai} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoCmw} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoAstra} className="img-rolling-costumer" alt="" />
            </div>
            <div className="px-4 mx-1 d-flex align-items-center justify-content-center ">
              <img src={logoNki} className="img-rolling-costumer" alt="" />
            </div>
          </div>
        </div>

        <div className="text-center mt-md-4  " style={{ zIndex: 10 }}>
          <p className="title-text ">
            Take a look at our{" "}
            <span className="text-color-1 primary-title-text-2">
              {" "}
              DISCOVERY!{" "}
            </span>{" "}
          </p>
        </div>
        <div className="col-12 " style={{ zIndex: 10 }}>
          <div id="carouselExample" className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className=" row m-auto col-9 p-2">
                  <div className="col-md-6 d-flex align-items-center justify-content-center ">
                    <img
                      src={projectAndon}
                      className="img-info-product"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 text-center">
                    <span className="title-text ">Production Andon</span>
                    <p className="content-text mt-4">
                      Streamline your manufacturing process with real-time
                      visibility and control. With customizable features and
                      intuitive interface, this innovative solution empowers you
                      to monitor production status, identify bottlenecks, and
                      optimize workflow efficiency. Enhance collaboration, boost
                      productivity, and achieve operational excellence with
                      Custom Production Andon.
                    </p>
                    <div className=" mt-4 col-md-12 row justify-content-evenly ">
                      <button className="btn btn-sm col-4 btn-outline-dark fw-bold">
                        Study Case
                      </button>
                      <button className="btn btn-sm col-4 btn-dark  fw-bold">
                        Discuss with Experts
                      </button>
                    </div>
                  </div>
                </div>
              </div>
           
            
            
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <BsArrowLeftCircleFill
                className="fs-2 fw-bold"
                style={{ color: "black" }}
              />

              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next "
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <BsArrowRightCircleFill
                className="fs-2 fw-bold"
                style={{ color: "black" }}
              />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAndProduction;
